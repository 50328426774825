import './App.css';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import Button from './Button';
import AppForm from './Form';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React for good!
        </a>
        <Button />
        <AppForm />
      </header>
    </div>
  );
}

export default withLDProvider({
  clientSideID: '603607419a3f9b0a69724116'
})(App);;

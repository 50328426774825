import React from 'react';
import { Form, Field } from 'react-final-form';
import { TextField } from 'final-form-material-ui';
import axios from 'axios'
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
    Paper,
    Grid,
    Button
} from '@material-ui/core';

const onSubmit = async values => {
    const result = await axios({
        url: "https://bb-form-endpoint.azurewebsites.net/api/FormEndpoint?code=y8k9i73XZRF5AjXxYjK6W7iHLrj66IGzBPZIJPit6jqswZrpEQcIZw==",
        method: "post",
        data: values
    }
    )
    window.alert(result.data);
};

const validate = values => {
    const errors = {};
    if (!values.firstName) {
        errors.firstName = 'Required';
    }
    if (!values.lastName) {
        errors.lastName = 'Required';
    }
    return errors;
};

const AppForm = () => {
    return (
        <div style={{ padding: 16, margin: 'auto', maxWidth: 600 }}>
            <Form
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Paper style={{ padding: 16 }}>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        name="firstName"
                                        component={TextField}
                                        type="text"
                                        label="First Name"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Field
                                        fullWidth
                                        required
                                        name="lastName"
                                        component={TextField}
                                        type="text"
                                        label="Last Name"
                                    />
                                </Grid>
                                <Grid item style={{ marginTop: 16 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                    >Submit</Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </form>)} />
        </div>
    )
};

export default AppForm;